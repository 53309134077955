import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { useAppDispatch, useAppSelector } from 'store'
import $business from 'store/business'
import $customer from 'store/customer'
import $service from 'store/service'
import $resource from 'store/resource'
import $day from 'store/day'

import App from 'components/App'

import styles from './AppointmentOrderSuccess.module.scss'

export type Props = {
  onClose: () => void
}

const AppointmentOrderSuccess: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const customer = useAppSelector(({ $customer }) => $customer.data)
  const settings = useAppSelector($business.get.settings)
  const service = useAppSelector($service.get.selected)
  const resource = useAppSelector($resource.get.selected)
  const formatTime = useAppSelector($day.get.formatTime)
  const temp = useAppSelector(({ $customer }) => $customer.temp)

  useEffect(() => {
    if (temp.phone !== '' && temp.name !== '' && !customer.loggedIn) {
      localStorage.setItem('customerTemp', JSON.stringify(temp))
      dispatch($customer.set.loggedIn(true))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp, customer.loggedIn])

  return  (
    <App.Flex column aCenter gap={6} className={styles.container}>
      <App.Flex center className={styles.icon}>
        <App.Icon icon="check" xl primary />
      </App.Flex>

      <App.Text center large bold>{t('Your booking successful!')}</App.Text>

      <App.Text center muted>{t('Prepare for the best beauty service experience in you lifer. Please, let us know if you have some changes in your plans.')}</App.Text>

      <App.Flex column fullWidth gap={2} className={styles.details}>
        <App.Text bold>{service.name}</App.Text>

        <App.Flex column gap={1}>
          <App.Text xs gray>{t('Details')}:</App.Text>

          <App.Flex row wrap gap={2}>
            {settings.display_duration ? <App.Tag icon="clock">{moment(formatTime).format('D')} {t(moment(formatTime).format('MMMM'))}, {moment(formatTime).format('HH:mm')}</App.Tag> : null}
            {resource?.id ? <App.Tag icon="user">{resource.name}</App.Tag> : null}
            {settings.display_price ? <App.Tag>₪{service.price}</App.Tag> : null}
          </App.Flex>
        </App.Flex>
      </App.Flex>

      <App.Button fullWidth onClick={onClose}>{t('Got it!')}</App.Button>
    </App.Flex>
  )
}

export default AppointmentOrderSuccess