import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { fetchWhat } from 'utils/api'

import { useAppDispatch, useAppSelector } from 'store'
import $app from 'store/app'
import $business from 'store/business'
import $customer from 'store/customer'
import $category from 'store/category'
import $service from 'store/service'
import $resource from 'store/resource'
import $day from 'store/day'
import $order from 'store/order'
import $wlist from 'store/wlist'

import App from 'components/App'
import Dialog from 'components/Dialog'
import HomeHero from 'components/Home/HomeHero'
import HomeHeader from 'components/Home/HomeHeader'
import HomeReservations from 'components/Home/HomeReservations'
import HomeContactUs from 'components/Home/HomeContactUs'
import AppointmentDialog from 'components/Appointment/AppointmentDialog'

import styles from './HomeContent.module.scss'

const HomeContent: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const reset = useAppSelector(({ $app }) => $app.reset)
  const showContent = useAppSelector(({ $app }) => $app.showContent)
  const settings = useAppSelector($business.get.settings)
  const customer = useAppSelector(({ $customer }) => $customer.data)
  const temp = useAppSelector(({ $customer }) => $customer.temp)
  const phone = useAppSelector($customer.get.phone)
  const editId = useAppSelector(({ $order }) => $order.editId)
  const whitelist = useAppSelector(({ $app }) => $app.whitelist)
  const wlistId = useAppSelector(({ $wlist }) => $wlist.id)

  const [error, setError] = useState({ phone: '', name: '' })
  const [loading, setLoading] = useState(false)
  const [scrollBottom, setScrollBottom] = useState(false)
  const [dialogVisible, setDialogVisible] = useState({ confirm: false, notAllowBooking: false, appointment: false })

  const contentRef = useRef<HTMLDivElement>(null)
  const phoneRef = useRef<HTMLInputElement>(null)
  const nameRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (reset) {
      dispatch($order.set.fetch(true))

      setTimeout(() => {
        dispatch($category.set.reset())
        dispatch($service.set.reset())
        dispatch($resource.set.reset())
        dispatch($day.set.reset())
        dispatch($order.set.reset())
        dispatch($wlist.set.reset())
        dispatch($app.set.reset(false))
      }, 300)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  useEffect(() => {
    if (showContent) {
      const customerPhone = localStorage.getItem('customerPhone')
      if (customerPhone) {
        localStorage.removeItem('customerTemp')

        dispatch($customer.set.loggedIn(true))
        fetchCustomer(customerPhone, true)
      }

      const customerTemp = localStorage.getItem('customerTemp')
      if (customerTemp) {
        const temp = JSON.parse(customerTemp)
        dispatch($customer.set.loggedIn(true))
        dispatch($customer.set.temp(temp))

        const isWhitelisted = whitelist.find(item => item === temp.phone)
        dispatch($customer.set.confirmed(!!isWhitelisted))
      }

      if (contentRef.current) {
        processShadow(contentRef.current)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContent])

  useEffect(() => {
    if (customer.loaded) {
      if (!customer.loggedIn && !customer.allow_book) {
        phoneRef.current?.blur()
        handleDialogOpen('notAllowBooking')()
      }

      const isWhitelisted = whitelist.find(item => item === phone)
      dispatch($customer.set.confirmed(!!isWhitelisted))

      if (!customer.loggedIn && customer?.id) {
        if (!isWhitelisted && !settings.disable_confirmation_code) {
          handleDialogOpen('confirm')()
        } else {
          handleCodeSuccess()
        }
      }

      if (wlistId) {
        prepareForWlistBooking()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer.loaded])

  const prepareForWlistBooking = async () => {
    await fetchWhat(dispatch, phone)

    dispatch($order.set.step(2))
    handleDialogOpen('appointment')()
  }

  const fetchCustomer = async (customerPhone: string, loggedIn: boolean = false) => {
    setLoading(true)
    
    const result = await $customer.api.get({ number: customerPhone })
    if (result && result.status === 'success') {
      const { allow_book, customer, status } = result
      dispatch($customer.set.data({ allow_book, customer, status }))

      if (!customer) {
        if (!wlistId) {
          if (loggedIn) {
            dispatch($customer.set.loaded(false))
          }
          dispatch($customer.set.loggedIn(false))
        } else {
          localStorage.removeItem('customerPhone')
          localStorage.setItem('customerTemp', JSON.stringify(temp))

          const isWhitelisted = whitelist.find(item => item === temp.phone)
          dispatch($customer.set.confirmed(!!isWhitelisted))
        }
      }
    } else {
      dispatch($app.set.error({ message: t(result?.message) }))
    }

    setLoading(false)
  }

  const handleDialogOpen = (key: string) => () => {
    setDialogVisible({ ...dialogVisible, [key]: true })
  }

  const handleDialogClose = (key: string) => () => {
    setDialogVisible({ ...dialogVisible, [key]: false })

    if (key === 'appointment') {
      dispatch($order.set.editId(null))
    }
  }

  const handleFormChange = (field: string) => async (value: string) => {
    setError({ phone: '', name: '' })

    const formatedValue = field === 'phone' ? removeSpecialSymbols(value) : value
    dispatch($customer.set.temp({ ...temp, [field]: formatedValue }))

    if (field === 'phone') {
      const formatedPhone = isValidNumber(formatedValue)
      if (formatedPhone) {
        if (!customer.loaded) {
          await fetchCustomer(formatedPhone)
        }
      } else {
        dispatch($customer.set.loaded(false))
      }
    }
  }

  const removeSpecialSymbols = (inputValue: string) => {
    let value = inputValue.replace(/\D/g, '')
    return value
  }

  const isValidNumber = (value: string) => {
    return value.length >= 10 ? value : false
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    processShadow(event.currentTarget)
  }

  const processShadow = (div: HTMLDivElement) => {
    const { scrollTop, clientHeight, scrollHeight } = div

    if (scrollTop + clientHeight + 1 >= scrollHeight) {
      setScrollBottom(true)
    } else {
      if (scrollBottom === true) {
        setScrollBottom(false)
      }
    }
  }

  const handleAppointment = () => {
    if (!customer.loggedIn) {
      if (phone === '') {
        setError({ ...error, phone: t('Please enter a valid phone number') })
        phoneRef.current?.scrollIntoView({ behavior: 'smooth' })
        return
      } else {
        if (temp.name === '') {
          setError({ ...error, name: t('Please enter your name') })
          nameRef.current?.scrollIntoView({ behavior: 'smooth' })
          return
        }
      }
    }

    // PWA.prompt()
    handleDialogOpen('appointment')()
  }

  const handleCodeSuccess = () => {
    localStorage.setItem('customerPhone', phone)
    dispatch($customer.set.loggedIn(true))
  }

  const handleLogout = () => {
    dispatch($customer.set.logout())
    dispatch($order.set.fetch(true))
  }

  const isRtl = useAppSelector(({ $app }) => $app.isRtl)

  return (
    <App.Flex column className={styles.container}>
      <div ref={contentRef} className={styles.content} onScroll={handleScroll}>
        <App.Flex column className={styles.scroll}>
          <App.Flex column jEnd className={styles.top}>
            <HomeHero />
          </App.Flex>

          <App.Flex column fullWidth gap={8} className={styles.scrollContent}>
            <App.Flex className={styles.topDiv} />

            <App.Flex column gap={4}>
              {customer.loggedIn ? (
                <App.Flex center className={styles.logout} onClick={handleLogout}>
                  <App.Icon icon="sign-out" small rotate={isRtl} />
                </App.Flex>
              ) : null}
              <HomeHeader />

              {customer.loggedIn ? (
                <App.Flex row center gap={4}>
                  {!customer.confirmed ? (
                    <App.Button flex={1} small outlined onClick={handleDialogOpen('confirm')}>{t('My reservations')}</App.Button>
                  ) : null}
                </App.Flex>
              ) : null}
            </App.Flex>

            {customer.loggedIn ? (
              customer.confirmed ? (
                <HomeReservations />
              ) : null
            ) : (
              <App.Flex column>
                <App.Flex column gap={4}>
                  <App.Text bold height={1.2}>{t('Phone number')}:</App.Text>
                  <App.Input
                    ref={phoneRef}
                    type="tel"
                    placeholder={t('Enter your phone number')}
                    value={temp.phone}
                    error={error.phone}
                    loading={loading}
                    limit={12}
                    onChange={handleFormChange('phone')}
                  />
                </App.Flex>

                <App.Flex column gap={4} className={cn(styles.nameBox, {[styles.open]: customer.loaded && !customer?.id && customer.allow_book})}>
                  <App.Flex column fullWidth className={styles.nameBoxInner}>
                    <App.Input
                      ref={nameRef}
                      placeholder={t('Enter your name')}
                      value={temp.name}
                      error={error.name}
                      onChange={handleFormChange('name')}
                    />
                  </App.Flex>
                </App.Flex>

                <App.Flex column gap={4} className={cn(styles.codeBox, {[styles.open]: customer.loaded && customer?.id})}>
                  <App.Flex column fullWidth className={styles.codeBoxInner}>
                    <App.Button outlined onClick={handleDialogOpen('confirm')}>{t('Enter a code')}</App.Button>
                  </App.Flex>
                </App.Flex>
              </App.Flex>
            )}

            <HomeContactUs />

            <App.Flex center gap={1}>
              <App.Text xs light gray>&copy; {t('Powered by')}</App.Text>
              <App.Text tag="a" href="https://tiffulit.co.il" target="_blank" rel="noreferrer" xs semiBold highlight>{t('TIFFULIT')}</App.Text>
            </App.Flex>
          </App.Flex>
        </App.Flex>
      </div>

      <App.Flex center padding={6} className={cn(styles.button, {[styles.bottom]: scrollBottom})}>
        <App.Button fullWidth disabled={customer.loaded && !customer.allow_book} onClick={handleAppointment}>{t('Make Appointment')}</App.Button>
      </App.Flex>

      <AppointmentDialog open={dialogVisible.appointment || editId != null} onClose={handleDialogClose('appointment')} />
      <Dialog.CodeConfirm open={dialogVisible.confirm} onSuccess={handleCodeSuccess} onClose={handleDialogClose('confirm')} />
      <Dialog.NotAllowBooking open={dialogVisible.notAllowBooking} onClose={handleDialogClose('notAllowBooking')} />
      <Dialog.CancelOrderConfirm />
      <Dialog.WlistCalendar />
      <Dialog.WlistTime />
      <Dialog.Error />
    </App.Flex>
  )
}

export default HomeContent