import cn from 'classnames'

import helper from './helper'

export type Props = {
  icon: string
  size?: number
  color?: string
  large?: boolean
  small?: boolean
  xs?: boolean
  xl?: boolean
  variant?: string | Array<string>
  muted?: boolean
  primary?: boolean
  alternate?: boolean
  danger?: boolean
  white?: boolean
  rotate?: boolean
  style?: React.CSSProperties
}

const AppIcon: React.FC<Props> = (props) => {
  const classes = [
    ...helper.init(props),
    helper.size(props),
    helper.variant(props),
    helper.rotate(props),
  ]

  const styles = {
    fill: helper.color(props),
    ...(props.style ?? {}),
  }

  const getIcon = () => {
    if (props.icon === 'home') {
      return (
        <svg className={cn(classes)} style={styles} viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none">
          <path d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2z"></path>
          <path d="M9 22L9 12 15 12 15 22"></path>
        </svg>
      )
    }

    if (props.icon === 'edit') {
      return (
        <svg className={cn(classes)} style={styles} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25 4.03125C24.2344 4.03125 23.4844 4.32812 22.9062 4.90625L13 14.7812L12.7812 15L12.7188 15.3125L12.0312 18.8125L11.7188 20.2812L13.1875 19.9688L16.6875 19.2812L17 19.2188L17.2188 19L27.0938 9.09375C28.2461 7.94141 28.2461 6.05859 27.0938 4.90625C26.5156 4.32812 25.7656 4.03125 25 4.03125ZM25 5.96875C25.2344 5.96875 25.4648 6.08984 25.6875 6.3125C26.1328 6.75781 26.1328 7.24219 25.6875 7.6875L16 17.375L14.2812 17.7188L14.625 16L24.3125 6.3125C24.5352 6.08984 24.7656 5.96875 25 5.96875ZM4 8V28H24V14.8125L22 16.8125V26H6V10H15.1875L17.1875 8H4Z" />
        </svg>
      )
    }

    if (props.icon === 'trash') {
      return (
        <svg className={cn(classes)} style={styles} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 4C14.4766 4 13.9414 4.18359 13.5625 4.5625C13.1836 4.94141 13 5.47656 13 6V7H7V9H8V25C8 26.6445 9.35547 28 11 28H23C24.6445 28 26 26.6445 26 25V9H27V7H21V6C21 5.47656 20.8164 4.94141 20.4375 4.5625C20.0586 4.18359 19.5234 4 19 4H15ZM15 6H19V7H15V6ZM10 9H24V25C24 25.5547 23.5547 26 23 26H11C10.4453 26 10 25.5547 10 25V9ZM12 12V23H14V12H12ZM16 12V23H18V12H16ZM20 12V23H22V12H20Z" />
        </svg>
      )
    }

    if (props.icon === 'map-pin') {
      return (
        <svg className={cn(classes)} style={styles} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M12 8.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM8.25 11a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z" clipRule="evenodd" />
          <path clipRule="evenodd"fillRule="evenodd" d="M12 3.75a7.25 7.25 0 00-5.127 12.376l4.244 4.243a1.25 1.25 0 001.767 0l4.243-4.243A7.25 7.25 0 0012 3.75zm6.187 13.437a8.75 8.75 0 10-12.374 0l4.244 4.243a2.75 2.75 0 003.887 0l4.243-4.243z" />
        </svg>
      )
    }

    if (props.icon === 'chevron-left') {
      return (
        <svg className={cn(classes)} style={styles} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.51562 2.14062L4.01562 7.64062L3.67188 8L4.01562 8.35938L9.51562 13.8594L10.2344 13.1406L5.09375 8L10.2344 2.85938L9.51562 2.14062Z" />
        </svg>
      )
    }

    if (props.icon === 'chevron-right') {
      return (
        <svg className={cn(classes)} style={styles} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.72656 3.21094L8.64844 4.28906L16.3594 12L8.64844 19.7109L9.72656 20.7891L17.9766 12.5391L18.4922 12L17.9766 11.4609L9.72656 3.21094Z" />
        </svg>
      )
    }

    if (props.icon === 'chevron-down') {
      return (
        <svg className={cn(classes)} style={styles} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.10938 5.39062L1.39062 6.10938L7.64062 12.3594L8 12.7031L8.35938 12.3594L14.6094 6.10938L13.8906 5.39062L8 11.2812L2.10938 5.39062Z" />
        </svg>
      )
    }

    if (props.icon === 'clock') {
      return (
        <svg className={cn(classes)} style={styles} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 2C4.69141 2 2 4.69141 2 8C2 11.3086 4.69141 14 8 14C11.3086 14 14 11.3086 14 8C14 4.69141 11.3086 2 8 2ZM8 3C10.7676 3 13 5.23242 13 8C13 10.7676 10.7676 13 8 13C5.23242 13 3 10.7676 3 8C3 5.23242 5.23242 3 8 3ZM7.5 4V8.5H11V7.5H8.5V4H7.5Z" />
        </svg>
      )
    }

    if (props.icon === 'calendar') {
      return (
        <svg className={cn(classes)} style={styles} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.75 3v.75h-3v16.5h16.5V3.75h-3V3h-1.5v.75h-7.5V3h-1.5zm-1.5 2.25h1.5V6h1.5v-.75h7.5V6h1.5v-.75h1.5v1.5H5.25v-1.5zm0 3h13.5v10.5H5.25V8.25zm4.5 1.5v1.5h1.5v-1.5h-1.5zm3 0v1.5h1.5v-1.5h-1.5zm3 0v1.5h1.5v-1.5h-1.5zm-9 3v1.5h1.5v-1.5h-1.5zm3 0v1.5h1.5v-1.5h-1.5zm3 0v1.5h1.5v-1.5h-1.5zm3 0v1.5h1.5v-1.5h-1.5zm-9 3v1.5h1.5v-1.5h-1.5zm3 0v1.5h1.5v-1.5h-1.5zm3 0v1.5h1.5v-1.5h-1.5z" />
        </svg>
      )
    }

    if (props.icon === 'check') {
      return (
        <svg className={cn(classes)} style={styles} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.2461 5.49609L10.125 20.6172L3.75391 14.2461L2.49609 15.5039L9.49609 22.5039L10.125 23.1055L10.7539 22.5039L26.5039 6.75391L25.2461 5.49609Z" />
        </svg>
      )
    }

    if (props.icon === 'user') {
      return (
        <svg className={cn(classes)} style={styles} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 3.75C9.1084 3.75 6.75 6.1084 6.75 9C6.75 10.8076 7.67285 12.4131 9.07031 13.3594C6.39551 14.5078 4.5 17.1621 4.5 20.25H6C6 16.9277 8.67773 14.25 12 14.25C15.3223 14.25 18 16.9277 18 20.25H19.5C19.5 17.1621 17.6045 14.5078 14.9297 13.3594C16.3271 12.4131 17.25 10.8076 17.25 9C17.25 6.1084 14.8916 3.75 12 3.75ZM12 5.25C14.0801 5.25 15.75 6.91992 15.75 9C15.75 11.0801 14.0801 12.75 12 12.75C9.91992 12.75 8.25 11.0801 8.25 9C8.25 6.91992 9.91992 5.25 12 5.25Z" />
        </svg>
      )
    }

    if (props.icon === 'error') {
      return (
        <svg className={cn(classes)} style={styles} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 0C5.38281 0 0 5.38281 0 12C0 18.6172 5.38281 24 12 24C18.6172 24 24 18.6172 24 12C24 5.38281 18.6172 0 12 0ZM12 2C17.5352 2 22 6.46484 22 12C22 17.5352 17.5352 22 12 22C6.46484 22 2 17.5352 2 12C2 6.46484 6.46484 2 12 2ZM11 6V14H13V6H11ZM11 16V18H13V16H11Z" />
        </svg>
      )
    }

    if (props.icon === 'sign-out') {
      return (
        <svg className={cn(classes)} style={styles} viewBox="0 0 471.2 471.2" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M227.619,444.2h-122.9c-33.4,0-60.5-27.2-60.5-60.5V87.5c0-33.4,27.2-60.5,60.5-60.5h124.9c7.5,0,13.5-6,13.5-13.5 s-6-13.5-13.5-13.5h-124.9c-48.3,0-87.5,39.3-87.5,87.5v296.2c0,48.3,39.3,87.5,87.5,87.5h122.9c7.5,0,13.5-6,13.5-13.5 S235.019,444.2,227.619,444.2z"/>
          <path d="M450.019,226.1l-85.8-85.8c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1l62.8,62.8h-273.9c-7.5,0-13.5,6-13.5,13.5 s6,13.5,13.5,13.5h273.9l-62.8,62.8c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.8-85.8 C455.319,239.9,455.319,231.3,450.019,226.1z"/>
        </svg>
      )
    }

    return null
  }

  return getIcon()
}

export default AppIcon