import DialogCancelOrderConfirm from './DialogCancelOrderConfirm'
import DialogCodeConfirm from './DialogCodeConfirm'
import DialogError from './DialogError'
import DialogNotAllowBooking from './DialogNotAllowBooking'
import DialogWlistCalendar from './DialogWlistCalendar'
import DialogWlistTime from './DialogWlistTime'

const Dialog = {
  CancelOrderConfirm: DialogCancelOrderConfirm,
  CodeConfirm: DialogCodeConfirm,
  Error: DialogError,
  NotAllowBooking: DialogNotAllowBooking,
  WlistCalendar: DialogWlistCalendar,
  WlistTime: DialogWlistTime,
}

export default Dialog