import { useTranslation } from 'react-i18next'
import moment from 'moment'
import cn from 'classnames'

import { useAppSelector } from 'store'

import App from 'components/App'

import styles from './HelperCalendar.module.scss'

export type Props = {
  month: number
  year: number
  activeDays: number[]
  selectedDay: number | null
  onMonthChange?: (month: number, year: number) => void
  onDayChange?: (day: number | null) => void
}

const HelperCalendar: React.FC<Props> = ({ month, year, activeDays, selectedDay, onMonthChange, onDayChange }) => {
  const { t } = useTranslation()

  const isRtl = useAppSelector(({ $app }) => $app.isRtl)

  const now = moment()
  const startOfMonth = moment().year(year).month(month - 1).startOf('month')
  const endOfMonth = moment().year(year).month(month - 1).endOf('month')
  const isCurrentMonth = now.isSame(startOfMonth, 'month')

  const daysToPrepend = startOfMonth.day()
  const daysToAppend = 6 - endOfMonth.day()

  const daysInMonth = Array.from({ length: endOfMonth.date() }, (_, i) => {
    const status = activeDays.includes(i + 1) ? (selectedDay === (i + 1) ? 'selected' : 'active') : 'current'

    return {
      value: startOfMonth.clone().add(i, 'days'),
      status,
    }
  })

  const prependDays = Array.from({ length: daysToPrepend }, (_, i) => ({value: startOfMonth.clone().subtract(i + 1, 'days'), status: 'inactive'})).reverse()
  const appendDays = Array.from({ length: daysToAppend }, (_, i) => ({value: endOfMonth.clone().add(i + 1, 'days'), status: 'inactive'}))

  const calendarDays = [...prependDays, ...daysInMonth, ...appendDays]

  const weekDays = [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')]

  const handleMonth = (direction: string) => () => {
    if (direction === 'prev' && isCurrentMonth) {
      return
    }

    let newMonth = month
    let newYear = year
    if (direction === 'prev') {
      newMonth = month === 1 ? 12 : month - 1
      newYear = month === 1 ? year - 1 : year
    }

    if (direction === 'next') {
      newMonth = month === 12 ? 1 : month + 1
      newYear = month === 12 ? year + 1 : year
    }
    if (onMonthChange) {
      onMonthChange(newMonth, newYear)
    }
  }

  const handleDayToggle = (date: number) => () => {
    if (onDayChange) {
      onDayChange(date === selectedDay ? null : date)
    }
  }

  return (
    <App.Flex column gap={4}>
      <App.Flex aCenter jBetween>
        <App.Flex center className={cn(styles.arrow, {[styles.disabled]: isCurrentMonth})} onClick={handleMonth('prev')}>
          <App.Icon icon="chevron-left" size={24} rotate={isRtl} />
        </App.Flex>

        <App.Text center small bold>{t(startOfMonth.format('MMMM'))} {year}</App.Text>

        <App.Flex center className={cn(styles.arrow, {[styles.rtl]: isRtl})} onClick={handleMonth('next')}>
          <App.Icon icon="chevron-right" size={24} rotate={isRtl} />
        </App.Flex>
      </App.Flex>

      <App.Flex direction="column">
        <App.Flex row aCenter gap={2}>
          {weekDays.map(day => (
            <App.Flex key={day} padding={2} center className={styles.weekDay}>
              <App.Text center xs uppercase highlight>{day}</App.Text>
            </App.Flex>
          ))}
        </App.Flex>

        <App.Flex wrap aCenter gap={2}>
          {calendarDays.map((day, index) => (
            <App.Flex key={index} center className={cn(styles.day, styles[day.status])} onClick={day.status === 'active' || day.status === 'selected' ? handleDayToggle(day.value.date()) : () => {}}>
              <App.Text center small bold highlight={day.status !== 'selected'} white={day.status === 'selected'}>{day.value.date()}</App.Text>
            </App.Flex>
          ))}
        </App.Flex>
      </App.Flex>
    </App.Flex>
  )
}

export default HelperCalendar