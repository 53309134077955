import moment from 'moment'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { request, businessCode } from './index'

export type DayType = {[date: string]: Array<string>}

type StateType = {
  id: string | null
  enabled: boolean
  dialog: string | null
  all: Array<DayType>
  month: number,
  year: number,
  selectedDay: number | null
  selectedFrom: string | null
  selectedTo: string | null
}

const initialState: StateType = {
  id: null,
  dialog: null,
  enabled: false,
  all: [],
  month: Number(moment().format('M')),
  year: Number(moment().format('YYYY')),
  selectedDay: null,
  selectedFrom: null,
  selectedTo: null,
}

export const wlistSlice = createSlice({
  name: '$wlist',

  initialState,

  reducers: {
    id: (state: StateType, action: PayloadAction<string | null>) => {
      state.id = action.payload
    },

    enabled: (state: StateType, action: PayloadAction<boolean>) => {
      state.enabled = action.payload
    },

    dialog: (state: StateType, action: PayloadAction<string | null>) => {
      state.dialog = action.payload
    },

    all: (state: StateType, action: PayloadAction<Array<DayType>>) => {
      state.all = action.payload
    },

    month: (state: StateType, action: PayloadAction<{month: number, year: number, day?: number, from?: string, to?: string}>) => {
      state.month = action.payload.month
      state.year = action.payload.year
      state.selectedDay = action.payload?.day ?? null
      state.selectedFrom = action.payload?.from ?? null
      state.selectedTo = action.payload?.to ?? null
    },

    selectedDay: (state: StateType, action: PayloadAction<number | null>) => {
      state.selectedDay = action.payload
    },

    selectedFrom: (state: StateType, action: PayloadAction<string | null>) => {
      state.selectedFrom = action.payload
    },

    selectedTo: (state: StateType, action: PayloadAction<string | null>) => {
      state.selectedTo = action.payload
    },

    reset: (state: StateType) => {
      state.id = null
      state.dialog = null
      state.enabled = false
      state.all = []
      state.month = Number(moment().format('M'))
      state.year = Number(moment().format('YYYY'))
      state.selectedDay = null
      state.selectedFrom = null
      state.selectedTo = null
    },
  },
})

const get = {
  days: createSelector([
    state => state.$wlist.all,
  ], (days) => {
    const keys = Object.keys(days)
    return keys.map(key => Number(moment(key, 'YYYY-MM-DD').format('D')))
  }),

  times: createSelector([
    state => state.$wlist.all,
    state => state.$wlist.year,
    state => state.$wlist.month,
    state => state.$wlist.selectedDay,
  ], (days, year, month, day) => {
    return day && days ? days[moment().year(year).month(month - 1).date(day).format('YYYY-MM-DD')] : []
  }),

  formatTime: (type: string) => createSelector([
    state => state.$wlist.year,
    state => state.$wlist.month,
    state => state.$wlist.selectedDay,
    state => state.$wlist.selectedFrom,
    state => state.$wlist.selectedTo,
  ], (year, month, day, from, to) => {
    const time = type === 'from' ? from : to
    if (day && time) {
      const [hours, minutes] = time.split(':').map(Number)

      return moment().year(year).month(month - 1).date(day).set({
        hour: hours,
        minute: minutes,
      }).format('YYYY-MM-DD HH:mm')
    }

    return null
  }),

  selectedDate: createSelector([
    state => state.$wlist.selectedDay,
    state => state.$wlist.month,
    state => state.$wlist.year,
  ], (day, month, year) => {
    return day ? `${day} ${moment().year(year).month(month - 1).date(day).format('MMMM')} ${year}` : null
  }),
}

const api = {
  all: (params: object) => {
    return request(`days/id/${businessCode()}`, 'POST', params)
  },

  add: (params: object) => {
    return request(`waiting_list/id/${businessCode()}`, 'POST', params)
  },

  get: (params: object) => {
    return request(`get_waiting_list/id/${businessCode()}`, 'POST', params)
  },
}

const wlist = {
  reducer: wlistSlice.reducer,
  set: wlistSlice.actions,
  get,
  api,
}

export default wlist