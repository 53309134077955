import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'

import $app from './app'
import $business from './business'
import $customer from './customer'
import $category from './category'
import $service from './service'
import $resource from './resource'
import $day from './day'
import $order from './order'
import $wlist from './wlist'

const store = configureStore({
  reducer: {
    $app: $app.reducer,
    $business: $business.reducer,
    $customer: $customer.reducer,
    $category: $category.reducer,
    $service: $service.reducer,
    $resource: $resource.reducer,
    $day: $day.reducer,
    $order: $order.reducer,
    $wlist: $wlist.reducer,
  },
})

export const businessCode = () => {
  return store.getState().$business.code
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()

export default store



export const API_URL = process.env.REACT_APP_API_URL

export const request = async (uri: string, method: string = 'GET', data: object | null = null) => {
  const options: RequestInit = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: null,
  }

  let query = ''
  if (data) {
    if (method.toUpperCase() === 'GET') {
      query = queryBuilder(data)
    } else {
      options.body = JSON.stringify(data)
    }
  }
  
  // const token = store.getState().$auth.token
  // if (token) {
  //   options.headers.Authorization = `Bearer ${token}`
  // }

  const response = await fetch(API_URL + uri + query, options)
  if (response.status === 401) {
    // store.dispatch($auth.set.logout())
    return
  }

  const result = await response.json()
  if (response.status === 200) {
    return resultHandler(result)
  } else {
    // messageHandler(result.messages)
    return null
  }
}

export type ApiResponse = {
  status: string
  [key: string]: any
}

const resultHandler = (result: ApiResponse) => {
  // const version: string = store.getState().$app.version
  // if (version != null && version != result.version) {
  //   window.location.reload()
  // }
  // store.dispatch($app.set.version(result.version))

  // messageHandler(result.messages)

  return result
}

const queryBuilder = (data: any) => {
  const params = new URLSearchParams()

  for (const key in data) {
    if (data[key] != null) {
      if (Array.isArray(data[key])) {
        for (const value of data[key]) {
          params.append(key, value)
        }
      } else {
        params.append(key, data[key])
      }
    }
  }
  
  const queryString = params.toString()
  return queryString ? `?${queryString}` : ''
}