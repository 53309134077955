import { Link } from 'react-router-dom'
import cn from 'classnames'

import helper from './helper'

import AppFlex from 'components/App/AppFlex'
import AppLoader from 'components/App/AppLoader'
import AppIcon from 'components/App/AppIcon'

export type Props = {
  children?: React.ReactNode
  tag?: React.ElementType
  icon?: string
  iconAppend?: boolean
  iconRotate?: boolean
  remote?: boolean
  href?: string
  variant?: string | Array<string>
  primary?: boolean
  outlined?: boolean
  small?: boolean
  large?: boolean
  size?: string | Array<string>
  width?: string | number | Array<string | number>
  fullWidth?: boolean
  halfWidth?: boolean
  flex?: number | Array<number>
  loading?: boolean
  disabled?: boolean | Array<boolean>
  style?: React.CSSProperties
  onClick?: () => void
}

const AppButton: React.FC<Props> = (props) => {
  const Tag: React.ElementType = props.tag ?? (props.href ? (props.remote ? 'a' : Link) : 'button')

  const classes = [
    ...helper.init(props),
    helper.variant(props),
    helper.disabled(props),
    helper.outlined(props),
    helper.size(props),
    helper.flex(props),
    helper.widthClass(props),
  ]

  const styles = {
    width: helper.width(props),
    ...(props.style ?? {}),
  }

  const handleClick = () => {
    if (props.onClick && !props.disabled && !props.loading) {
      props.onClick()
    }
  }

  return (
    <Tag
      to={!props.remote ? props.href : null}
      href={props.remote ? props.href : null}
      target={props.remote ? '_blank' : null}
      rel={props.remote ? 'noreferrer' : null}
      className={cn(classes)}
      style={styles}
      onClick={handleClick}
    >
      {props.loading ? (
        <AppFlex tag="span" center>
          <AppLoader button large={props.large} small={props.small} primary={props.primary} alternate={props.outlined} />
        </AppFlex>
      ) : (
        props.icon && !props.iconAppend ? (
          <AppIcon icon={props.icon} rotate={props.iconRotate} large={props.large} small={props.small} />
        ) : null
      )}

      {props.children}

      {props.icon && props.iconAppend ? (
        <AppIcon icon={props.icon} rotate={props.iconRotate} large={props.large} small={props.small} />
      ) : null}
    </Tag>
  )
}

export default AppButton