import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'store'
import $app from 'store/app'
import $business from 'store/business'
import $customer from 'store/customer'
import $order from 'store/order'
import $service from 'store/service'
import $resource from 'store/resource'
import $day from 'store/day'
import $wlist from 'store/wlist'

import App from 'components/App'
import ConfirmDialog from 'components/Dialog/DialogCodeConfirm'

import styles from './AppointmentFooter.module.scss'

const AppointmentFooter: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const step = useAppSelector(({ $order }) => $order.step)
  const customer = useAppSelector(({ $customer }) => $customer.data)
  const settings = useAppSelector($business.get.settings)
  const service = useAppSelector($service.get.selected)
  const isServicesDisabled = useAppSelector($service.get.isDisabled)
  const resource = useAppSelector($resource.get.selected)
  const isResourcesDisabled = useAppSelector($resource.get.isDisabled)
  const formatTime = useAppSelector($day.get.formatTime)
  const phone = useAppSelector($customer.get.phone)
  const name = useAppSelector($customer.get.name)
  const comment = useAppSelector(({ $order }) => $order.comment)
  const editId = useAppSelector(({ $order }) => $order.editId)
  const whitelist = useAppSelector(({ $app }) => $app.whitelist)
  const wlistEnabled = useAppSelector(({ $wlist }) => $wlist.enabled)
  const formatFrom = useAppSelector($wlist.get.formatTime('from'))
  const formatTo = useAppSelector($wlist.get.formatTime('to'))

  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
  const [canMakeOrder, setCanMakeOrder] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (canMakeOrder) {
      createOrder()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canMakeOrder])

  const getButtonText = () => {
    switch (step) {
      case 1:
        return t('Select Date')
      case 2:
        return wlistEnabled ? t('Add to Waiting List') : t('Finish Appointment')
      default:
        return ''
    }
  }

  const isButtonDisabled = () => {
    if (step === 1) {
      if (!service?.id || (settings.display_resource && !resource?.id) || isServicesDisabled || isResourcesDisabled) {
        return true
      }
    }

    if (step === 2) {
      if (wlistEnabled) {
        if (!formatFrom || !formatTo) {
          return true
        }
      } else {
        if (!formatTime) {
          return true
        }
      }
    }

    return false
  }

  const handleClick = () => {
    if (!isButtonDisabled()) {
      if (step === 1) {
        dispatch($order.set.step(step + 1))
        return
      }

      if (step === 2) {
        if (wlistEnabled) {
          createWlist()
        } else {
          checkForOrder()
        }
        return
      }
    }
  }

  const checkForOrder = () => {
    const isWhitelisted = whitelist.find(item => item === phone)
    if (!customer?.id && !isWhitelisted) {
      setConfirmDialogVisible(true)
    } else {
      setCanMakeOrder(true)
    }
  }

  const createOrder = async () => {
    setLoading(true)

    const params = {
      number: phone,
      name,
      resource: resource?.id ?? null,
      service: service.id,
      date: formatTime,
      notes: comment,
    }

    let result = null
    if (editId) {
      result = await $order.api.update({...params, order_id: editId})
    } else {
      result = await $order.api.book(params)
    }

    if (result && result.status === 'success') {
      dispatch($order.set.step(3))
    } else {
      dispatch($app.set.error({
        message: t('Please check all input data and the selected time and date to ensure everything is correct and try again.'),
        button: t('Try again'),
      }))
    }

    setLoading(false)
    setCanMakeOrder(false)
  }

  const createWlist = async () => {
    setLoading(true)

    const params = {
      number: phone,
      name,
      resource: resource?.id ?? null,
      service: service.id,
      from: formatFrom,
      to: formatTo,
    }

    const result = await $wlist.api.add(params)
    if (result && result.status === 'success') {
      dispatch($order.set.step(3))
    } else {
      dispatch($app.set.error({
        message: t('Please check all input data and the selected time and date to ensure everything is correct and try again.'),
        button: t('Try again'),
      }))
    }

    setLoading(false)
  }

  const handleConfirmDialogClose = () => {
    setConfirmDialogVisible(false)
  }

  const handleCodeSuccess = () => {
    setCanMakeOrder(true)
  }

  return (
    <App.Flex row className={styles.container}>
      <App.Flex row gap={2} fullWidth aCenter jBetween className={styles.content}>
        <App.Flex column gap={2}>
          {settings.display_price ? (
            <App.Flex row aCenter gap={1}>
              <App.Text xs height={1}>{t('Total')}:</App.Text>
              <App.Text large bold highlight height={1}>₪{service?.price ?? 0}</App.Text>
            </App.Flex>
          ) : null}

          {service ? (
            <App.Text xs muted height={1}>{service.name}</App.Text>
          ) : null}
        </App.Flex>

        <App.Button disabled={isButtonDisabled()} loading={loading} onClick={handleClick}>{getButtonText()}</App.Button>
      </App.Flex>

      <ConfirmDialog open={confirmDialogVisible} onSuccess={handleCodeSuccess} onClose={handleConfirmDialogClose} />
    </App.Flex>
  )
}

export default AppointmentFooter