import { useState } from 'react'

import { useAppDispatch, useAppSelector } from 'store'
import $app from 'store/app'

import App from 'components/App'
import AppointmentHeader from 'components/Appointment/AppointmentHeader'
import AppointmentFooter from 'components/Appointment/AppointmentFooter'
import AppointmentWhat from 'components/Appointment/AppointmentWhat'
import AppointmentWhen from 'components/Appointment/AppointmentWhen'
import AppointmentOrderSuccess from 'components/Appointment/AppointmentOrderSuccess'
import AppointmentWlistSuccess from 'components/Appointment/AppointmentWlistSuccess'


import styles from './AppointmentDialog.module.scss'

export type Props = {
  open: boolean
  onClose: () => void
}

const AppointmentDialog: React.FC<Props> = ({ open, onClose }) => {
  const dispatch = useAppDispatch()
  const step = useAppSelector(({ $order }) => $order.step)
  const selectedCategoryIds = useAppSelector(({ $category }) => $category.selectedIds)
  const month = useAppSelector(({ $day }) => $day.month)
  const wlistEnabled = useAppSelector(({ $wlist }) => $wlist.enabled)

  const [scrollDown, setScrollDown] = useState(false)

  const handleScrollDown = (value: boolean) => {
    setScrollDown(value)
  }

  const handleClose = () => {
    if (step === 3) {
      dispatch($app.set.reset(true))
    }

    if (onClose) {
      onClose()
    }
  }

  return (
    <App.Dialog
      open={open}
      scrollDown={scrollDown}
      actions={step === 3 ? null : <AppointmentFooter key={step} />}
      scrollDependensies={step === 1 ? selectedCategoryIds : month}
      whiteBack
      onClose={handleClose}
    >
      <App.Flex column className={styles.container}>
        {step !== 3 ? <AppointmentHeader onClose={handleClose} /> : null}

        {step === 1 ? <AppointmentWhat onScrollDown={handleScrollDown} /> : null}
        {step === 2 ? <AppointmentWhen onScrollDown={handleScrollDown} /> : null}
        {step === 3 ? wlistEnabled ? <AppointmentWlistSuccess onClose={handleClose} /> : <AppointmentOrderSuccess onClose={handleClose} /> : null}
      </App.Flex>
    </App.Dialog>
  )
}

export default AppointmentDialog