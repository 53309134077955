import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'store'
import $app from 'store/app'
import $customer from 'store/customer'
import $wlist from 'store/wlist'

import App from 'components/App'
import Helper from 'components/Helper'

import styles from './DialogWlistCalendar.module.scss'

const DialogWlistCalendar: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const dialog = useAppSelector(({ $wlist }) => $wlist.dialog)
  const month = useAppSelector(({ $wlist }) => $wlist.month)
  const year = useAppSelector(({ $wlist }) => $wlist.year)
  const days = useAppSelector($wlist.get.days)
  const selectedDay = useAppSelector(({ $wlist }) => $wlist.selectedDay)
  const phone = useAppSelector($customer.get.phone)
  const selectedServiceId = useAppSelector(({ $service }) => $service.selectedId)
  const selectedResourceId = useAppSelector(({ $resource }) => $resource.selectedId)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (dialog === 'calendar') {
      fetchDays()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, dialog])

  const fetchDays = async () => {
    setLoading(true)

    const result = await $wlist.api.all({
      number: phone,
      month,
      year,
      service: selectedServiceId,
      resource: selectedResourceId,
      order_id: null,
      waiting_list: true,
    })

    if (result && result.status === 'success') {
      dispatch($wlist.set.all(result.data))
    } else {
      dispatch($app.set.error({ message: t(result?.message) }))
    }

    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  const handleMonth = (newMonth: number, newYear: number) => {
    dispatch($wlist.set.month({ month: newMonth, year: newYear }))
  }

  const handleDay = (day: number | null) => {
    dispatch($wlist.set.selectedDay(day))
    handleClose()
  }

  const handleClose = () => {
    dispatch($wlist.set.dialog(null))
  }

  return (
    <App.Dialog open={dialog === 'calendar'} onClose={handleClose}>
      <App.Flex column gap={6} className={styles.container}>
        {loading ? (
          <App.Flex center height={305}>
            <App.Loader xl />
          </App.Flex>
        ) : (
          <Helper.Calendar
            month={month}
            year={year}
            activeDays={days}
            selectedDay={selectedDay}
            onMonthChange={handleMonth}
            onDayChange={handleDay}
          />
        )}
      </App.Flex>
    </App.Dialog>
  )
}

export default DialogWlistCalendar